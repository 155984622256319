<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>ميثاق المتعاملين</h3></div>
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h4 class="blue--text">
          شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
        </h4>
        <span class="ml-2 mr-2">/</span
        ><span class="gray--text">ميثاق المتعاملين</span>
      </v-row>
      <div id="app">
        <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
          <PDFJSViewer :path="`${path}`" :fileName="`${name}`" />
        </v-col>
      </div>
    </v-container>
  </div>
</template>
<script>
import PDFJSViewer from "../../components/PDFJSViewer.vue";
export default {
  name: "Anelsherka",
  components: {
    PDFJSViewer,
  },
  data() {
    return {
      name: "CustomerCharter.pdf", //change which pdf file loads
      path: "lib/web/viewer.html", //path of the PDF.js viewer.html
    };
  },
};
</script>
<style scoped>
.Labimg {
  box-shadow: gray 3px 3px 3px 3px;
  border-image: 5px white;
}
.divider {
  width: 2px;
  background: #1f659b;

  height: 100%;
  margin-right: 10px !important;

  margin-top: 30px;
  margin-bottom: 30px;
}
.changeTitleStyleTrain {
  background: rgba(222, 184, 135, 0.192) !important;
  color: gray !important;
}
@media screen and (max-width: 1260px) {
  .divider {
    width: 0px !important;
  }
}
</style>
